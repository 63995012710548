const BE_BASE = process.env.REACT_APP_BE_BASE
export const BE_URL = BE_BASE + '/api';

export const PAGES = {
    UPLOAD: 'upload',
    OVERVIEW: 'devices',
    EVENTS: 'events'
}

export const ACTIONS = {
    CREATE: 'create',
    REVERT: 'revert',
    UPDATE: 'update'
}

export const testerColumns = [
    "nap",
    "uap",
    "lap",
    "hwRevisionMajor",
    "hwRevisionMinor",
    "fwRevisionMajor",
    "fwRevisionMinor",
    "crystalCalibrationCoarse",
    "crystalCalibrationFine",
    "calibratedFrequency",
    "peakPower",
    "voltageLDO",
    //"voltageSmps1V8",
    // "voltageSmps1V1",
    "currentIdle",
    "currentRfActive",
    "productionDate",
    "testResult",
    "suid",
    "panelNumber",
    "encryptionStatus",
    "productionComments",
    'comment'
]

export const DEVICE_TEST_STATES = {
    NEW_DEVICE: 'NEW',
    UNFINISHED_DEVICE: 'UNFINISHED',
    TESTED_DEVICE_PASS: 'PASSED',
    TESTED_DEVICE_FAIL: 'FAILED',
    TESTED_DEVICE_ERROR: 'ERROR',
};

export const TEST_STEP_STATUS = {
    NOT_STARTED: 'NOT_STARTED',
    RUNNING: 'RUNNING',
    SUCCESS: 'SUCCESS',
    FAILED: 'FAILED',
    ERROR: 'ERROR',
}
