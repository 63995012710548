import { Card, Skeleton, Space} from "antd";
import {Input} from "../../ui";

export const Form = ({form, setForm}) => {


  const disabledList = [
    '_id',
    'owner',
    'suid',
    'mac',
    'created',
    'ownerAlias'
  ]

  const hiddenList = [
    'events',
    'owner',
    '_id',
    'tests',
    'comments',
  ]
  return (
    <Card style={{padding: 12, flex: 1}}>
      <Space direction='vertical' size="large">

        {form && (
          <Space wrap>
            {Object.entries(form)
              .filter(([key]) => !hiddenList.includes(key))
              .map(([key]) => (
                <Input
                  key={key}
                  value={form[key]}
                  onChange={(value) => setForm({...form, [key]: value})}
                  label={key}
                  disabled={disabledList.includes(key)}
                />
              ))}

          </Space>
        )}
        {!form && (
          <Skeleton/>
        )}
      </Space>
    </Card>
  )
}
