import React, {useState} from "react";
import {Alert, Layout} from "antd";

import {TopMenu} from "./TopMenu";
import {
  Upload,
  Detail,
  Events,
  Devices
} from "./pages";
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
} from "react-router-dom";

const {Content} = Layout

function App() {
  const [key, setKey] = useState('')

  const RouteLayout = () => (
    <Layout style={{height: '100%'}}>
      <TopMenu token={key} setKey={setKey}/>
      {key && (
        <Content style={{height: '100%', overflowY: 'scroll'}}>
          <Outlet/>
        </Content>
      )}
      {!key && (
        <Alert type='info' message='Verify Key to continue'/>
      )}
    </Layout>
  )

  const router = createBrowserRouter([
    {
      element: <RouteLayout/>,
      children: [
        {
          path: "/",
          element: <Devices token={key}/>,
        },
        {
          path: "/devices",
          element: <Devices token={key}/>,
        },
        {
          path: "/device/:id",
          element: <Detail token={key}/>,
        },
        {
          path: "/events",
          element: <Events token={key} />,
        },
        {
          path: "/upload",
          element: <Upload token={key}/>,
        }
      ]
    }
  ]);

  return <RouterProvider router={router}/>
}

export default App;
