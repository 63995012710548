import {Alert, Col, Collapse, Space, Timeline, Tooltip} from "antd";
import {ACTIONS, DEVICE_TEST_STATES, TEST_STEP_STATUS} from "../../constants";
import dayjs from "dayjs";
import {InfoCircleOutlined} from "@ant-design/icons";

const googleURL = "https://drive.google.com/file/d/"

export const TestTimeline = ({tests, events, generateDummyTest}) => {

  const formUrl = (data) => {
    if(data.id) {
      return `${googleURL}${data.id}/view`
    }
    return ""
  }

  const decideType = (status, steps) => {
    if (steps?.some((step) => step.test_step_status === TEST_STEP_STATUS.FAILED)) {
      return 'error'
    }
    switch (status) {
      case TEST_STEP_STATUS.SUCCESS:
      case DEVICE_TEST_STATES.TESTED_DEVICE_PASS:
        return 'success'
      case TEST_STEP_STATUS.FAILED:
      case TEST_STEP_STATUS.ERROR:
      case DEVICE_TEST_STATES.TESTED_DEVICE_FAIL:
      case DEVICE_TEST_STATES.TESTED_DEVICE_ERROR:
        return 'error'
      default:
        return 'info'
    }
  }
  if (!Array.isArray(tests)) {
    return null
  }

  const showMinutes = (ms) => {
    const minutes = Math.floor(ms / 60000)
    const seconds = ((ms % 60000) / 1000).toFixed(0)
    return minutes + ':' + (seconds < 10 ? '0' : '') + seconds
  }
  return (
    <Col span={8}>
      <Space direction='vertical'>
        <Space>
          <div style={{fontSize: 20, fontWeight: 'bold'}}>Barel tests</div>
          {/*<Button onClick={generateDummyTest}>
            Generate dummy test for this device
          </Button>*/}
        </Space>
        {!!tests?.length && (
          <Timeline
            items={tests
              ?.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
              .map((testGroup) => {
                const imageData = testGroup.additionalData?.imageData
                return ({
                  color: (testGroup.status === ACTIONS.REVERT || events.some((item2) => item2.revertedId === testGroup.id)) ? 'gray' : 'blue',
                  children: (
                    <>
                      <Collapse
                        items={[
                          {
                            key: testGroup.id,
                            label: (
                              <div
                                style={{textDecoration: testGroup.reverted ? 'line-through' : 'none'}}
                              >
                                {`
                                ${dayjs(testGroup.timestamp).format('DD.MM.YY hh:mm')} -
                                ${testGroup.ownerAlias}
                              `}
                              </div>
                            ),
                            children: (
                              <Space direction='vertical'>
                                <div>
                                  {testGroup.additionalData?.testTime && `Test time: ${showMinutes(testGroup.additionalData.testTime)}`}
                                </div>
                                {console.log(testGroup)}
                                {Array.isArray(testGroup.testData) && testGroup.testData?.map((test) => (
                                  <Alert
                                    key={test.test_name}
                                    type={decideType(test.test_status, test.steps)}
                                    message={
                                      <Collapse
                                        ghost
                                        items={[{
                                          key: test.test_name,
                                          label: (
                                            <Space>
                                              {`
                                              ${test.test_status.toUpperCase()} -
                                              ${test.test_name}
                                            `}
                                              <Tooltip
                                                title={test.test_description}
                                              >
                                                <InfoCircleOutlined/>
                                              </Tooltip>
                                            </Space>
                                          ),
                                          children: (
                                            <Space direction='vertical'>
                                              {test.test_steps.map((step) => (
                                                <Alert
                                                  key={step.test_step_name}
                                                  type={decideType(step.test_step_status)}
                                                  message={
                                                    <Space direction={'vertical'}>
                                                      <div>
                                                        {`
                                                        ${step.test_step_status.toUpperCase()} -
                                                        ${step.test_step_name}
                                                      `}
                                                        <Tooltip title={step.test_step_description}>
                                                          <InfoCircleOutlined/>
                                                        </Tooltip>
                                                      </div>
                                                      {step.test_step_value && (
                                                        <div style={{display: 'flex'}}>
                                                          {'Value: '}
                                                          <div style={{whiteSpace: 'pre'}}>
                                                            {JSON.stringify(step.test_step_value, null, 2)}
                                                          </div>
                                                        </div>
                                                      )}
                                                      {step.test_step_command === 'AUDIO_RECORD_STEP' && (
                                                        <div>
                                                          {imageData && (
                                                            <>
                                                              <div style={{display: 'flex', gap: 20}}>
                                                                <div style={{display: 'flex', gap: 5}}>
                                                                  <div>Left:{imageData.state?.toUpperCase()}</div>
                                                                  <a target="_blank" href={formUrl(imageData.left)}>Image</a>
                                                                </div>
                                                                <div style={{display: 'flex', gap: 5}}>
                                                                  <div>Right: {imageData.state?.toUpperCase()}</div>
                                                                  <a target="_blank" href={formUrl(imageData.right)}>Image</a>
                                                                </div>
                                                              </div>
                                                            </>
                                                          )}
                                                        </div>
                                                      )}
                                                    </Space>
                                                  }
                                                />
                                              ))}
                                            </Space>
                                          )
                                        }]}
                                      />
                                    }
                                    showIcon
                                  />
                                ))}
                              </Space>
                            )
                          }
                        ]}
                      />
                    </>
                  ),
                });
              })}
          />
        )}
      </Space>
    </Col>
  )
}
